document.body.addEventListener('click', () => {
    document.querySelector('.mfooter-lang-change').classList.remove('visible');
    document.querySelector('.mfooter-popup').classList.remove('visible');
});
document.querySelector('.mfooter-popup').addEventListener('click', (e) => {
    e.stopPropagation();
});
document.querySelector('.mfooter-lang-change').addEventListener('click', (e) => {
    e.stopPropagation();
    document.querySelector('.mfooter-lang-change').classList.toggle('visible');
    document.querySelector('.mfooter-popup').classList.toggle('visible');
});
